.App {
  text-align: center;
}

.bold {
  font-weight: bold;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
}

.navlink {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.App-header {
  /* background-color: #5f6269; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Admin {
  width: 100%;
  max-width: 800px;
  padding: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.locationImage {
  align-items: center;
  text-align: center;
}

.locationName {
  font-size: large;
}

.preview {
  margin-top: 10px;
}

.Admin input:not([type="radio"]),
textarea,
select {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 15px;
  font-size: 14px;
}

/* input[type="radio"] {
  display: inline;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 2px solid black;
  padding: 1px 1px;
  font-size: 14px;
} */

label {
  line-height: 2;
  text-align: left;
  display: block;
  color: black;
  font-size: 14px;
  font-weight: 200;
}

.center {
  margin-top: 30px;
  display: block;
  text-align: center;
}

a, .link {
  color: #000;
  text-decoration: underline #ffca28;
}
